import type {Core, Root, RootServiceFactory, Service} from '@ncwallet-app/core';
import {CommonCoreService} from '@ncwallet-app/core';

import TelegramRootService from './TelegramRootService';

export default class TelegramRootServiceFactoryImpl
  implements RootServiceFactory
{
  createCore(): Core & Service {
    return new CommonCoreService();
  }

  createRoot(core: Core): Root & Service {
    return new TelegramRootService(core);
  }
}
