import {base32} from 'rfc4648';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class Base32Utils {
  static addPadding(base32String: string): string {
    const paddingLength = (8 - (base32String.length % 8)) % 8;
    return base32String + '='.repeat(paddingLength);
  }

  static decode(base32String: string): string {
    const paddedBase32String = Base32Utils.addPadding(base32String);
    const byteArray = base32.parse(paddedBase32String);
    const decoder = new TextDecoder();
    return decoder.decode(byteArray);
  }
}

export default Base32Utils;
