import type {
  Either,
  ErrorRepository,
  GlobalError,
  UnknownError,
} from '@ncwallet-app/core';
import {error, NOT_IMPLEMENTED, UNKNOWN_ERROR} from '@ncwallet-app/core';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import type {Sharing} from '@ncwallet-app/core/src/Sharing';

export default class SharingImpl implements Sharing {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly configuration: Configuration;
    },
  ) {}

  async shareFile(): Promise<Either<void, GlobalError>> {
    return error(this._root.errorRepository.create({kind: NOT_IMPLEMENTED}));
  }

  protected async _shareMessage(
    content: string,
    secondaryString?: string,
  ): Promise<void> {
    try {
      let shareString = `https://t.me/share/url?url=${content}`;
      if (secondaryString) {
        shareString = `${shareString}&text=${secondaryString}`;
      }
      Telegram.WebApp.openTelegramLink(shareString);
    } catch (raw) {
      this._root.errorRepository.create<UnknownError>({
        kind: UNKNOWN_ERROR,
        raw,
      });
    }
  }

  shareMessage = this._shareMessage.bind(this);
}
