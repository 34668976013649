import type {Either, GlobalError} from '@ncwallet-app/core';
import {RouterImpl, success} from '@ncwallet-app/core';
import type {
  AppleOAuth2Credentials,
  AppleOAuth2Provider,
  OAuth2OutcomeMap,
} from '@ncwallet-app/core/src/OAuth2Provider';

export default class StubAppleOAuth2ProviderService
  implements AppleOAuth2Provider
{
  signIn(): Either<void, GlobalError> {
    return success();
  }

  readonly outcome = new RouterImpl<OAuth2OutcomeMap<AppleOAuth2Credentials>>();
}
