import {variance} from '@ncwallet-app/core';
import type {AuthScreenFooterProps} from '@ncwallet-app/core/src/Components/AuthScreenFooter';
import {OAuthVariant} from '@ncwallet-app/navigation/src/screens/AuthScreen';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaLayout,
} from '@ncwallet-app/ui/src';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';

const TelegramAuthScreenFooter = observer(
  ({
    onLogInPress,
    nextEnvironmentButtonShown,
    onNextEnvironmentPress,
  }: AuthScreenFooterProps) => {
    const handleLogInPress = useCallback(() => {
      onLogInPress(OAuthVariant.Telegram);
    }, [onLogInPress]);

    return (
      <SafeAreaLayout insets={SafeAreaInset.BOTTOM}>
        <Root>
          <Button
            onPress={handleLogInPress}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            title="Sign in with Telegram"
            style={styles.button}
          />

          {nextEnvironmentButtonShown && (
            <Button
              onPress={onNextEnvironmentPress}
              variant={ButtonVariant.PrimaryLight}
              color={ButtonColor.Secondary}
              title="Next Environment"
              style={styles.button}
            />
          )}
        </Root>
      </SafeAreaLayout>
    );
  },
);

export default TelegramAuthScreenFooter;

const styles = StyleSheet.create({
  button: {
    marginBottom: 15,
  },
});
const Root = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    width: '100%',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 345,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    }),
  },
}));
