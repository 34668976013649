import {useStrings} from '@ncwallet-app/core';
import type {ProfileEmailButtonProps} from '@ncwallet-app/core/src/Components/ProfileEmailButton';
import ProfileSettingsItem from '@ncwallet-app/navigation/src/screens/ProfileSettingsScreen/ProfileSettingsItem';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';

const ProfileEmailButton = observer(
  ({
    getTitle: _getTitle,
    getError,
    onLinkToEmailPress,
    selected,
  }: ProfileEmailButtonProps) => {
    const strings = useStrings();

    const getTitle = useCallback(() => {
      return _getTitle() || '-';
    }, [_getTitle]);

    const isDisabled = useMemo(() => {
      return getTitle() !== '-';
    }, [getTitle]);

    return (
      <ProfileSettingsItem
        disabled={isDisabled}
        selected={selected}
        label={strings['linkToEmail.title']}
        getTitle={getTitle}
        onPress={onLinkToEmailPress}
        getError={getError}
      />
    );
  },
);

export default ProfileEmailButton;
