import type {Disposer, LocationSource, Service} from '@ncwallet-app/core';
import {batchDisposers} from '@ncwallet-app/core';
import type {AccountLinkageHandler} from '@ncwallet-app/core/src/AccountLinkageHandler/AccountLinkageHandler';
import type {AppStateHelper} from '@ncwallet-app/core/src/AppStateHelper';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import {unwrap} from '@ncwallet-app/core/src/EitherAdapter';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {
  AuthSuccessMessage,
  LinkToEmailService,
} from '@ncwallet-app/core/src/LinkToEmailService';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import type {AccountProviderMap} from '@ncwallet-app/core/src/NCWalletServer/LinkOauth';
import type {OAuth2ProviderMap} from '@ncwallet-app/core/src/OAuth2RestClient';
import type {TelegramMiniApp} from '@ncwallet-app/core/src/TelegramMiniApp';
import {when} from 'mobx';
import {nanoid} from 'nanoid';

import {IN_APP_DATA_PREFIX} from '../InstallReferrerIdentification';
import {DEBUG_START_PARAM} from '../TelegramDebugLocationDetectorService/debugConstants';
import {UrlUtils} from '../utils';

export default class LinkToEmailServiceImpl
  implements LinkToEmailService, Service
{
  constructor(
    protected readonly _root: {
      readonly locationSource: LocationSource;
      readonly flashMessage: FlashMessage;
      readonly appStateHelper: AppStateHelper;
      readonly localization: Localization;
      readonly configuration: Configuration;
      readonly accountLinkageHandler: AccountLinkageHandler;
      readonly telegramMiniApp: TelegramMiniApp;
    },
  ) {}

  private _authWindow: Window | null = null;

  private _getProviderType = (key: string): keyof AccountProviderMap => {
    switch (key) {
      case 'a':
        return 'apple';
      case 't':
        return 'telegram';
      default:
        return 'google';
    }
  };

  private async _link(oauthData: string) {
    await when(() => this._root.appStateHelper.isReadyToMakeRequests);

    try {
      const provider = this._getProviderType(oauthData[0]);
      const token = oauthData.substring(1) as TokenType;

      await this._root.accountLinkageHandler.link(provider, token);

      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation(
          'linkToEmail.messages.success',
        ),
        variant: 'success',
      });
    } catch {
      this._root.flashMessage.showMessage({
        title: this._root.localization.getTranslation(
          'linkToEmail.messages.error',
        ),
        variant: 'danger',
      });
    }
  }

  private async _init() {
    const initialSource = await unwrap(this._root.locationSource.getInitial());
    const startParam = UrlUtils.geTgWebAppStartParam(initialSource);

    const isDebugActivationLink = startParam === DEBUG_START_PARAM;
    const isInAppData = startParam?.startsWith(IN_APP_DATA_PREFIX);

    if (startParam === null || isDebugActivationLink || isInAppData) {
      return;
    }

    await this._link(startParam);
  }

  subscribe() {
    this._init().catch(() => {});
    return this._proceedLinkingIfMessage();
  }

  private _proceedLinkingIfMessage() {
    window.addEventListener('message', this._onLinkMessage);
    return batchDisposers((() => {
      window.removeEventListener('message', this._onLinkMessage);
    }) as Disposer);
  }

  private _onLinkMessage = async (event: MessageEvent<AuthSuccessMessage>) => {
    if (Object.is(event.data.type, 'authSuccess')) {
      this._authWindow?.close();
      await this._link(event.data.data.oauthData);
    }
  };

  linkWithEmail(type: keyof OAuth2ProviderMap, goToRoot: () => void) {
    const values = this._root.configuration.values;
    goToRoot();
    const url = new URL(`${values.miniAppUrl}/auth.html`);
    const state = `${values.telegramBotName}@${values.miniAppUrl}`;
    if (type === 'google') {
      const googleRedirectUri = values.googleRedirectUriForEmileLinking;
      const clientId = values.googleWebOauthClientId;
      const sp = url.searchParams;
      sp.set('service_url', 'https://accounts.google.com/o/oauth2/auth');
      sp.set('client_id', clientId);
      sp.set('response_type', 'code');
      sp.set('redirect_uri', googleRedirectUri);
      sp.set('scope', 'openid email profile');
      sp.set('prompt', 'select_account');
      sp.set('state', state);
      this._authWindow = window.open(url.toString(), '_blank');
    }
    if (type === 'apple') {
      const appleRedirectUri = values.appleRedirectUriForEmileLinking;
      const clientId = values.appleOauthClientId;
      const sp = url.searchParams;
      sp.set('service_url', 'https://appleid.apple.com/auth/authorize');
      sp.set('client_id', clientId);
      sp.set('redirect_uri', appleRedirectUri);
      sp.set('response_type', 'code id_token');
      sp.set('scope', 'name email');
      sp.set('response_mode', 'form_post');
      sp.set('state', state);
      sp.set('nonce', nanoid());
      this._authWindow = window.open(url.toString(), '_blank');
    }
    if (!('BroadcastChannel' in globalThis)) {
      this._root.telegramMiniApp.closeThisInstance();
    }
  }
}

type TokenType = AccountProviderMap[keyof AccountProviderMap];
