import type {
  AuthClient,
  AuthHelper,
  AuthResponse,
  Either,
  GlobalError,
  LocalAuthClientServiceDependencies,
  Service,
  TelegramBotName,
} from '@ncwallet-app/core';
import {LocalAuthClientService, UNAUTHORIZED} from '@ncwallet-app/core';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import type {TelegramMiniApp} from '@ncwallet-app/core/src/TelegramMiniApp';

export default class TelegramLocalAuthClientService
  extends LocalAuthClientService
  implements AuthClient, AuthHelper, Service
{
  constructor(
    protected readonly _root: {
      readonly telegramMiniApp: TelegramMiniApp;
      readonly configuration: Configuration;
    } & LocalAuthClientServiceDependencies,
  ) {
    super(_root);
  }

  protected async getInitialState(): Promise<
    Either<AuthResponse, GlobalError>
  > {
    const _initial = await super.getInitialState();
    if (_initial.success && _initial.right.kind !== UNAUTHORIZED) {
      return _initial;
    }
    return this.unsafePreSignIn({
      provider: 'telegram',
      token: this._root.telegramMiniApp.initData,
      telegram_bot_name: this._root.configuration.values
        .telegramBotName as TelegramBotName,
    });
  }
}
