import type {
  Either,
  ErrorRepository,
  GlobalError,
  Location,
  SpecialLocation,
  UnknownError,
  Url,
} from '@ncwallet-app/core';
import {EXTERNAL, failure, success, UNKNOWN_ERROR} from '@ncwallet-app/core';
import {BaseHeadlessLocationImpl} from '@ncwallet-app/core/src/Location';
import type {Navigation} from '@ncwallet-app/core/src/Navigation';

export default class TelegramLocationImpl
  extends BaseHeadlessLocationImpl
  implements Location
{
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
      readonly specialLocation: SpecialLocation;
      readonly navigation: Navigation;
    },
  ) {
    super(_root);
  }

  async open(locator: Url): Promise<Either<void, GlobalError>> {
    const parsed = this._root.specialLocation.parse(locator);
    if (parsed.kind === EXTERNAL) {
      try {
        Telegram.WebApp.openLink(locator);
        return success();
      } catch (raw) {
        return failure(
          this._root.errorRepository.create<UnknownError>({
            kind: UNKNOWN_ERROR,
            description: 'Opening url failed',
            raw,
          }),
        );
      }
    }

    return this._root.navigation.navigate(parsed.rest);
  }
}
