import {variance} from '@ncwallet-app/core';
import type {LogButtonProps} from '@ncwallet-app/core/src/Components/LogButton';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';

const LogButton = observer(({copyLog}: LogButtonProps) => {
  if (copyLog) {
    return (
      <StyledButton
        variant={ButtonVariant.ThemeButton}
        title="COPY LOG"
        onPress={copyLog}
      />
    );
  } else {
    return null;
  }
});

export default LogButton;

const StyledButton = variance(Button)(() => ({
  root: {
    marginBottom: 10,
  },
}));
