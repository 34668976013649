import type {Debug} from '@ncwallet-app/core/src/Configuration';
import type {LocationSource} from '@ncwallet-app/core/src/Location';
import type {Service} from '@ncwallet-app/core/src/structure';
import type {Url} from '@ncwallet-app/core/src/units';

import {IN_APP_DATA_PREFIX} from '../InstallReferrerIdentification';
import {UrlUtils} from '../utils';
import {DEBUG_START_PARAM} from './debugConstants';

export default class DebugLocationDetectorService implements Service {
  constructor(
    private readonly _root: {
      readonly debug: Debug;
      readonly locationSource: LocationSource;
    },
  ) {}

  private _NONCE = new RegExp(DEBUG_START_PARAM, 'gi');

  private async _processLocator(_: Url) {
    const hasNonce = this._NONCE.test(_);
    if (hasNonce) {
      await this._root.debug.enableDebug();
    }
  }

  private async _initialize() {
    const initial_ = await this._root.locationSource.getInitial();
    if (!initial_.success) {
      return;
    }
    const startParam = UrlUtils.geTgWebAppStartParam(initial_.right);
    if (startParam?.startsWith(IN_APP_DATA_PREFIX)) {
      return;
    }
    return this._processLocator(initial_.right);
  }

  subscribe() {
    void this._initialize();
    return this._root.locationSource.updates.listen(_ =>
      this._processLocator(_),
    );
  }
}
