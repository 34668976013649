import type {
  Disposer,
  NavigationContainer,
  Service,
  TelegramInitData,
  Url,
} from '@ncwallet-app/core';
import {batchDisposers} from '@ncwallet-app/core';
import type {TelegramMiniApp} from '@ncwallet-app/core/src/TelegramMiniApp';
import {makeObservable, observable, runInAction} from 'mobx';

export default class TelegramMiniAppService
  implements TelegramMiniApp, Service
{
  private _channel?: BroadcastChannel;
  readonly isAvailable = true;

  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
    },
  ) {
    makeObservable(this);
  }

  @observable private _appShouldBeClosed: boolean = false;

  get appShouldBeClosed() {
    return this._appShouldBeClosed;
  }

  get initData() {
    return tg.initData as TelegramInitData;
  }

  get isNativeMobileTelegramApp() {
    return tg.platform === 'ios' || tg.platform === 'android';
  }

  closeThisInstance() {
    tg.close();
  }

  openLink(url: Url) {
    tg.openLink(url);
  }

  private _closeOtherLocalInstances() {
    this._performWithChannel(_ => {
      _.postMessage(MESSAGE);
    });
  }

  private _init() {
    tg.ready();
    tg.expand();
    tg.disableVerticalSwipes();
    this._closeOtherLocalInstances();
  }

  private _listenNavigationChanges() {
    const {ref} = this._root.navigationContainer;
    if (!ref) {
      return;
    }

    const _handleGoBack = () => {
      if (ref.canGoBack()) {
        ref.goBack();
      } else {
        this.closeThisInstance();
      }
    };

    const unsubscribe = ref.addListener('state', () => {
      if (ref.canGoBack()) {
        Telegram.WebApp.BackButton.show();
        Telegram.WebApp.BackButton.onClick(_handleGoBack);
      } else {
        Telegram.WebApp.BackButton.hide();
        Telegram.WebApp.BackButton.offClick(_handleGoBack);
      }

      return () => {
        Telegram.WebApp.BackButton.offClick(_handleGoBack);
      };
    });

    return (() => {
      unsubscribe();
    }) as Disposer;
  }

  subscribe() {
    this._init();
    return batchDisposers(
      this._closeThisInstanceIfForcedTo(),
      (() => {
        this.closeThisInstance();
      }) as Disposer,
      this._listenNavigationChanges(),
    );
  }

  private _closeThisInstanceIfForcedTo() {
    const [channel, disposer] = this._getChannel();
    channel?.addEventListener('message', this._onMessage);
    return batchDisposers(
      (() =>
        channel?.removeEventListener('message', this._onMessage)) as Disposer,
      disposer,
    );
  }

  private readonly _onMessage = (event: MessageEvent<unknown>) => {
    if (Object.is(event.data, MESSAGE)) {
      // The inactive application doesn't always close, using the appShouldBeClosed flag, we will close the app when the user returns to it.
      runInAction(() => (this._appShouldBeClosed = true));
      this.closeThisInstance();
    }
  };

  private _performWithChannel(op: (_: BroadcastChannel) => void): void {
    const [channel, disposer] = this._getChannel();
    if (!channel) {
      return;
    }
    try {
      op(channel);
    } finally {
      disposer?.();
    }
  }

  private _getChannel(): [BroadcastChannel | undefined, Disposer?] {
    let channel: BroadcastChannel | undefined;
    try {
      if (this._channel) {
        return [this._channel];
      }
      channel = new BroadcastChannel(CHANNEL_NAME);
      this._channel = channel;
    } catch (error) {
      console.log(String(error));
    }
    return [
      channel,
      (() => {
        channel?.close();
        this._channel = undefined;
      }) as Disposer,
    ];
  }
}

export const CHANNEL_NAME = 'TelegramMiniAppService';
export const MESSAGE = 'TelegramMiniAppService';

const tg = Telegram.WebApp;
