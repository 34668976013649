import {useRoot, useStrings} from '@ncwallet-app/core';
import type {QrCodeScreenProps} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import {QrCodeScreen} from '@ncwallet-app/navigation/src/screens/QrCodeScreen';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';

const SCAN_QR_POPUP_CLOSED = 'scanQrPopupClosed';

const TelegramQrCodeScreen = observer(function ShowQrCodeScannerBinding(
  props: QrCodeScreenProps,
) {
  const {onCodeScanned, onClose} = props;
  const {telegramMiniApp} = useRoot();
  const {isNativeMobileTelegramApp} = telegramMiniApp;
  const strings = useStrings();
  const scannedCodeRef = useRef<string | undefined>();

  const handleScan = useCallback(
    (scan: string) => {
      Telegram.WebApp.closeScanQrPopup();
      onCodeScanned(scan);
    },
    [onCodeScanned],
  );

  const handleClose = useCallback(() => {
    if (scannedCodeRef.current) {
      return;
    }
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isNativeMobileTelegramApp) {
      const scanQrParams = {text: strings['qrCodeScreen.text']};
      Telegram.WebApp.showScanQrPopup(scanQrParams, code => {
        scannedCodeRef.current = code;
        handleScan(code);
      });
      Telegram.WebApp.onEvent(SCAN_QR_POPUP_CLOSED, handleClose);
      return () => {
        Telegram.WebApp.offEvent(SCAN_QR_POPUP_CLOSED, handleClose);
      };
    }
  }, [handleScan, isNativeMobileTelegramApp, onClose, strings, handleClose]);

  if (isNativeMobileTelegramApp) {
    return null;
  }

  return <QrCodeScreen {...props} />;
});

export default TelegramQrCodeScreen;
