import {useRoot} from '@ncwallet-app/core/';
import {useEffect, useState} from 'react';

import {UrlUtils} from '../../utils';

// eslint-disable-next-line import-x/prefer-default-export
export const useTelegramStartParam = () => {
  const {locationSource} = useRoot();
  const [telegramStartParam, seTelegramStartParam] = useState('');

  useEffect(() => {
    void locationSource.getInitial().then(result => {
      const startParam = UrlUtils.geTgWebAppStartParam(
        result.success ? result.right : '',
      );
      if (startParam) {
        seTelegramStartParam(startParam);
      }
    });
  }, [locationSource]);

  return telegramStartParam;
};
