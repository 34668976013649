import type {ExpiredTokenRecovery} from '@ncwallet-app/core/src/ExpiredTokenRecovery';
import type {TelegramMiniApp} from '@ncwallet-app/core/src/TelegramMiniApp';

export default class TelegramExpiredTokenRecoveryImpl
  implements ExpiredTokenRecovery
{
  constructor(
    private readonly _root: {
      readonly telegramMiniApp: TelegramMiniApp;
    },
  ) {}

  async recover() {
    this._root.telegramMiniApp.closeThisInstance();
  }
}
