import type {EXTERNAL_REF} from '@ncwallet-app/core/src/InstallReferrerIdentification/parseInstallReferrer';
import {default as commonInstallReferrerParser} from '@ncwallet-app/core/src/InstallReferrerIdentification/parseInstallReferrer';
import type {Url} from '@ncwallet-app/core/src/units/Uri';

import {Base32Utils, UrlUtils} from '../utils';
import {IN_APP_DATA_PREFIX} from './constants';

export default function parseInstallReferrer(
  _: Url,
): string | typeof EXTERNAL_REF | undefined {
  const startParam = UrlUtils.geTgWebAppStartParam(_);
  if (startParam?.startsWith(IN_APP_DATA_PREFIX)) {
    const decodedStartParams = Base32Utils.decode(
      startParam.slice(IN_APP_DATA_PREFIX.length),
    );
    return commonInstallReferrerParser(
      `https://app.ncwallet.net/${decodedStartParams}` as Url,
    );
  }
}
