import type {
  ErrorRepository,
  Http,
  Sharing,
  UnknownError,
} from '@ncwallet-app/core';
import {UNKNOWN_ERROR} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import {getAccountFromState} from '@ncwallet-app/core/src/AccountStore';
import type {Configuration} from '@ncwallet-app/core/src/Configuration';
import type {FlashMessage} from '@ncwallet-app/core/src/FlashMessage';
import type {Localization} from '@ncwallet-app/core/src/Localization';
import type {
  TransactionReport,
  TransactionReportHelper,
} from '@ncwallet-app/core/src/TransactionReport';
import {action, computed, makeObservable, observable} from 'mobx';

export default class TelegramTransactionReportImpl
  implements TransactionReport
{
  @observable private _isError = false;
  @observable private _isLoading = false;

  constructor(
    readonly _root: {
      readonly http: Http;
      readonly accountStore: AccountStore;
      readonly configuration: Configuration;
      readonly transactionReportHelper: TransactionReportHelper;
      readonly flashMessage: FlashMessage;
      readonly localization: Localization;
      readonly errorRepository: ErrorRepository;
      readonly sharing: Sharing;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get isError() {
    return this._isError;
  }

  @action.bound
  async shareReport() {
    try {
      const url = this._getReportUrl();
      void this._root.sharing.shareMessage(encodeURIComponent(url));
    } catch (raw) {
      this._root.errorRepository.create<UnknownError>({
        kind: UNKNOWN_ERROR,
        raw,
      });
    }
  }

  @action.bound
  async downloadReport() {
    try {
      const url = this._getReportUrl();
      Telegram.WebApp.openLink(url);
    } catch (raw) {
      this._root.errorRepository.create<UnknownError>({
        kind: UNKNOWN_ERROR,
        raw,
      });
    }
  }

  private _getReportUrl() {
    const token = this._root.transactionReportHelper.token;
    const accountId = getAccountFromState(this._root.accountStore.state)?.id;

    return `${this._root.configuration.current.values.ncWalletRestApiUrl}api/v1/reports?token=${token ?? ''}&account_id=${accountId ?? ''}`;
  }
}
